.article-wysi {
    display:block;
    p {
        margin-bottom:24px;
    }
    h3 {
        font-family: 'Athelas', serif;
        font-size: 24px;
        color: map-get($colors, coal);
        line-height: 32px;
        font-weight:600;
        margin-bottom:12px;
    }
    blockquote, blockquote p {
        margin:$gutter $gutter / 2;
        font-family:'Athelas', serif;
        font-size:36px;
        line-height:42px;
        font-weight:600;
        color:map-get($colors, red);
        text-align:center;
        @include below($s) {
            margin:6px;
            font-size:24px;
            line-height:30px;
        }
    }
    img {
        margin:$gutter auto;
        &.alignleft {
            float:left;
            margin:0;
            margin-right:$gutter * 2;
        }
        &.alignright {
            float:right;
            margin:0;
            margin-left:$gutter * 2;
        }
        &.aligncenter {
            margin:$gutter auto;
            display: block;
        }
        @include below($s) {
            float:none;
            width:100% !important;
            margin:0 !important;
            margin-bottom:$gutter !important;
            height: auto;
        }
    }
    ul {
        padding-left:20px;
        margin-bottom:24px;
    }
    li {
        font-family:'athelas', serif;
        font-size:18px;
        line-height:26px;
        color:map-get($colors, coal);
        margin:6px 0;
        @include below($s) {
            font-size:14px;
            line-height:22px;
        }
    }
    a {
        color:map-get($colors, red);
    }
}