.holder {
	display: block;
	margin: 0 auto;
	max-width: $contentWidth;
	position: relative;
	@include below($m) {
		padding:$contentPadding 24px;
	}
	@include below($s) {
		padding:12px;
	}
	&.wide {
		width:$siteMaxWidth;
		max-width:100%;
		padding:0;
	}
}
.simple-holder {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: $contentWidth;
}
.section-preamble {
	
	@include below($l) {
		margin-bottom:24px;
	}
}
.row {
	margin-top:$defaultMargin;
	width:100%;
	@include below($m) {
		margin-top: 20px;
	}
}
.flex {
	display: flex;
	flex-wrap:wrap;
	&.flex-margin {
		justify-content: space-between;

		.card, .contact-card {
			float:none !important;
			margin-right:0 !important;
		}
		&:after {
			content: "";
			flex: 1 1 100%;
			max-width: 32%;
		} 
	}
}
// Custom settings.
.align-center {
	text-align: center;
}
.col-right {
  float: right !important;
  box-sizing: border-box;
}

.col-center {
	display: block;
	float: none !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.col-1 {
	@include grid(1);
	&.no--gutter {
		@include grid(1, $gutter:0px);
	}
	@include below($m) {
		@include grid(1, $gutter / 2);
	}
}
.col-2 {
	@include grid(2);
	&.no--gutter {
		@include grid(2, $gutter:0px);
	}
	@include below($m) {
		@include grid(2, $gutter / 2);
	}
}
.col-3 {
	@include grid(3);
	&.no--gutter {
		@include grid(3, $gutter:0px);
	}
	&.double-gutter {
		@include grid(3, $gutter: $gutter * 2);
	}
	@include below($m) {
		@include grid(3, $gutter / 2);
	}
}
.col-4 {
	@include grid(4);
	&.no--gutter {
		@include grid(4, $gutter:0px);
	}
	@include below($m) {
		@include grid(4, $gutter / 2);
	}
}
.col-5 {
	@include grid(5);
	&.no--gutter {
		@include grid(5, $gutter:0px);
	}
	@include below($m) {
		@include grid(5, $gutter / 2);
	}
}
.col-6 {
	@include grid(6);
	&.no--gutter {
		@include grid(6, $gutter:0px);
	}
	@include below($m) {
		@include grid(6, $gutter / 2);
	}
}
.col-7 {
	@include grid(7);
	&.no--gutter {
		@include grid(7, $gutter:0px);
	}
	@include below($m) {
		@include grid(7, $gutter / 2);
	}
}
.col-8 {
	@include grid(8);
	&.no--gutter {
		@include grid(8, $gutter:0px);
		margin-right:0px;
	}
	@include below($m) {
		@include grid(8, $gutter / 2);
	}
}
.col-9 {
	@include grid(9);
	&.no--gutter {
		@include grid(9, $gutter:0px);
	}
	&.double-gutter {
		@include grid(9, $gutter: $gutter * 2);
	}
	@include below($m) {
		@include grid(9, $gutter / 2);
	}
}
.col-10 {
	@include grid(10);
	&.no--gutter {
		@include grid(10, $gutter:0px);
	}

	@include below($m) {
		@include grid(10, $gutter / 2);
	}
}
.col-11 {
	@include grid(11);
	&.no--gutter {
		@include grid(11, $gutter:0px);
	}
	@include below($m) {
		@include grid(11, $gutter / 2);
	}
}
.col-12 {
	@include grid(12);
	&.no--gutter {
		@include grid(12, $gutter:0px);
	}
	@include below($m) {
		@include grid(12, $gutter / 2);
	}
}

@include below($l) {
	.l-col-1 {
		@include grid(1);
		&.no--gutter {
			@include grid(1, $gutter:0px);
		}
	}
	.l-col-2 {
		@include grid(2);
		&.no--gutter {
			@include grid(2, $gutter:0px);
		}
	}
	.l-col-3 {
		@include grid(3);
		&.no--gutter {
			@include grid(3, $gutter:0px);
		}
	}
	.l-col-4 {
		@include grid(4);
		&.no--gutter {
			@include grid(4, $gutter:0px);
		}
	}
	.l-col-5 {
		@include grid(5);
		&.no--gutter {
			@include grid(5, $gutter:0px);
		}
	}
	.l-col-6 {
		@include grid(6);
		&.no--gutter {
			@include grid(6, $gutter:0px);
		}
	}
	.l-col-7 {
		@include grid(7);
		&.no--gutter {
			@include grid(7, $gutter:0px);
		}
	}
	.l-col-8 {
		@include grid(8);
		&.no--gutter {
			@include grid(8, $gutter:0px);
		}
	}
	.l-col-9 {
		@include grid(9);
		&.no--gutter {
			@include grid(9, $gutter:0px);
		}
	}
	.l-col-10 {
		@include grid(10);
		&.no--gutter {
			@include grid(10, $gutter:0px);
		}
	}
	.l-col-11 {
		@include grid(11);
		&.no--gutter {
			@include grid(11, $gutter:0px);
		}
	}
	.l-col-12 {
		width: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}
@include below($m) {
	.m-col-1{ @include grid(1, ($gutter / 2)); &.no--gutter{@include grid(1, $gutter:0px);} }
	.m-col-2{ @include grid(2, ($gutter / 2)); &.no--gutter{@include grid(2, $gutter:0px);} }
	.m-col-3{ @include grid(3, ($gutter / 2)); &.no--gutter{@include grid(3, $gutter:0px);} }
	.m-col-4{ @include grid(4, ($gutter / 2)); &.no--gutter{@include grid(4, $gutter:0px);} }
	.m-col-5{ @include grid(5, ($gutter / 2)); &.no--gutter{@include grid(5, $gutter:0px);} }
	.m-col-6{ @include grid(6, ($gutter / 2)); &.no--gutter{@include grid(6, $gutter:0px);} }
	.m-col-7{ @include grid(7, ($gutter / 2)); &.no--gutter{@include grid(7, $gutter:0px);} }
	.m-col-8{ @include grid(8, ($gutter / 2)); &.no--gutter{@include grid(8, $gutter:0px);} }
	.m-col-9{ @include grid(9, ($gutter / 2)); &.no--gutter{@include grid(9, $gutter:0px);} }
	.m-col-10{ @include grid(10, ($gutter / 2)); &.no--gutter{@include grid(10, $gutter:0px);} }
	.m-col-11{ @include grid(11, ($gutter / 2)); &.no--gutter{@include grid(11, $gutter:0px);} }
	.m-col-12{
		width: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

[class*="col-"]{
	float: left;
	position:relative;
	vertical-align: top;

	@include below($s) {
		width:100%;
	}
}
@include below($s){
	.col-1,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-10,
	.col-11,
	.col-12{
		width: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.s-col-1 {
		@include grid(1);
		&.no--gutter{
			@include grid(1, $gutter:0px);
		}
	}
	.s-col-2 {
		@include grid(2);
		&.no--gutter{
			@include grid(2, $gutter:0px);
		}
	}
	.s-col-3 {
		@include grid(3);
		&.no--gutter{
			@include grid(3, $gutter:0px);
		}
	}
	.s-col-4 {
		@include grid(4, $gutter:12px);
		&:nth-child(3n) {
			margin-right:0px !important;
		}
		&.no--gutter{
			@include grid(4, $gutter:0px);
		}
	}
	.s-col-5 {
		@include grid(5);
		&.no--gutter{
			@include grid(5, $gutter:0px);
		}
	}
	.s-col-6 {
		@include grid(6);
		&.no--gutter{
			@include grid(6, $gutter:0px);
		}
	}
	.s-col-7 {
		@include grid(7);
		&.no--gutter{
			@include grid(7, $gutter:0px);
		}
	}
	.s-col-8 {
		@include grid(8);
		&.no--gutter{
			@include grid(8, $gutter:0px);
		}
	}
	.s-col-9 {
		@include grid(9);
		&.no--gutter{
			@include grid(9, $gutter:0px);
		}
	}
	.s-col-10 {
		@include grid(10);
		&.no--gutter{
			@include grid(10, $gutter:0px);
		}
	}
	.s-col-11 {
		@include grid(11);
		&.no--gutter{
			@include grid(11, $gutter:0px);
		}
	}
	.s-col-12 {
		@include grid(12);
		&.no--gutter{
			@include grid(12, $gutter:0px);
		}
	}
}