.article-excerpt p {
    font-size: 32px;
    line-height: 40px;
    margin-bottom:24px;
    @include below($m) {
        font-size:18px;
        line-height:24px;
        max-width:100%;
        font-weight:600;
    }  
}
.search-item .article-excerpt p {
    font-size: 18px;
    line-height: 26px;
}
.page-article-wrap {
    position: relative;
    &.flex {
        flex-wrap:nowrap;
        @include below($m) {
            flex-wrap:wrap;
        }
    }
}
.important-links {
    margin-bottom:$gutter;
}
.page-article.centered-article {
    margin-left:auto;
    margin-right:auto;
    margin-top:0;
    
}
.alumni-image {
    display: inline-block;
    width:21%;
    margin-right:$gutter;
    position: relative;
    img {
        width:100%;
        height:auto;
        display: block;
        &:not(.pattern) {
            mix-blend-mode: multiply;
        }
    }
    .pattern {
        position: absolute;
        top:0;
        left:0;
    }
}
.alumni-text {
    display: inline-block;
    width:75%;
    vertical-align: top;                                            
    .name {
        font-family:'Edmondsans', sans-serif;
        font-weight:500;
        font-size: 18px;
        color: map-get($colors, coal);
        line-height: 26px;
    }
    .quote {
        font-size:36px;
        font-weight:600;
        line-height:42px;
        color:map-get($colors, red);
    }
}

.logo-wrap {
    display: flex;
    flex-wrap:wrap;
    width:100%;
    margin:$gutter auto;
    justify-content:center;
    align-items:center;
    .logo {
        margin-right:$gutter;
        margin-bottom:$gutter;
        img {
            max-width:220px;
            max-height:120px;
        }
    }
}
.chapter .logo-wrap {
    width:70%;
    @include below($m) {
        width:100%;
    }
}