.layout-lunch_menu_block {
    .matochmat-wrap {
        h3.heading {
            color: #E8361B !important;
            margin-bottom: 35px;
        }

        .lunch-item {
            padding-bottom: 40px;
            margin-top: 40px;
            
            h4 {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 16px;
                font-weight: 600;
            }
            
            p {
                font-size: 18px;
                line-height: 26px;
            }
        }

        .print-as-pdf,
        .menus-presented-by {
            color: #E8361B !important;

            a {
                color: #E8361B !important;
            }
        }
    }
}