.article-gallery {
    min-height:250px;
    margin:$gutter auto;
    padding:$gutter 0;
}
.swiper-container .gallery-image img {
    @include below($s) {
        width: 100%;
    }
}
.gallery-image {
    position: relative;
    display: block;
    img {
        display: block;
        width:100%;
        height:auto;
        position: relative;
        z-index:1;
    }
    &:after {
        content:'';
        width:100%;
        height:100%;
        background-image:url('../img/zoomin.png');
        background-size:cover;
        background-repeat: no-repeat;
        position: absolute;
        font-family:'Edmondsans', sans-serif;
        font-size:16px;
        font-weight:500;
        text-transform: uppercase;
        color:white;
        top:0;
        left:0;
        z-index:2;
        opacity:0;
        transition:.18s opacity ease-in-out;
    }
    &:hover:after {
        opacity:1;
    }
}
.slide-count {
    position: absolute;
    left:0;
    bottom:0;
    font-size:14px;
    font-weight:600;
    font-family:'Edmondsans', sans-serif;
    color:map-get($colors, red);
    @include below($s) {
        bottom:30px;
    }
}