.lunch {
    margin:$gutter * 2 0;
    h3 {
        text-align: center;
        margin-bottom:$gutter;
    }
}
.lunch-logo {
    margin:$gutter auto;
    width:100%;
    text-align:center;
}
.lunch-item {
    padding-bottom:$gutter / 2;
    margin-top:$gutter / 2;
    border-bottom:1px solid map-get($colors, bordergray);
    h4 {
        font-family:'Athelas', serif;
        font-size:24px;
        color:map-get($colors, coal);
        font-weight:600;
        margin-bottom:12px;
    }
}