.content-list-item {
    text-decoration: none;
    padding-bottom:24px;
    padding-top:24px;
    border-bottom:1px solid map-get($colors, bordergray);
    display: block;
    position: relative;
    padding-right:20%;
    transition: .12s all ease-in-out;
    .arrow-in-content-list {
        position: absolute;
        right:0;
        top:50%;
        transform:translate(0px, -50%);
        transition:transform .12s ease-out;
    }
    &:hover {
        
        h2 {
            text-decoration: underline;
        }
        .arrow-in-content-list {
            transform:translate(4px, -50%);
        }
    }
    .item-content, .item-image {
        display: inline-block;
    }
    .item-image {
        margin-right:$gutter;
        width:25%;
        position: relative;
        img {
            display: block;
            width:100%;
            height:auto;
            mix-blend-mode:multiply;
        }
    }
    .item-image + .item-content {
        width:70%;
        vertical-align: top;
    }
    @include below($m) {
        padding-right:12%;
    }
}