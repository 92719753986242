.header-symbol {
    margin-bottom:24px;
    @include below($m) {
        max-width:124px;
        margin-bottom:12px;
    }
}
.arrow-in-card {
    width:24px;
    @include below($s) {
        width:18px;
    }
}
.small-pattern {
    position: absolute;
    z-index:9;
    top:0;
    left:0;
}
.smallest-pattern {
    position: absolute;
    z-index:9;
    top:0;
    left:0;
    width:100%;
    mix-blend-mode: normal !important;
}
.arrow-in-article-card {
    display: inline-block;
    width:16px;
    margin-left:12px;
    position:relative;
    top:1px;
    transform:translateX(0px);
    transition:transform .12s ease-out;
    @include below($s) {
        width:12px;
    }
}
.small-arrow {
    display: inline-block;
    width:16px;
    margin-left:12px;
    position:relative;
    top:1px;
    transform:translateX(0px);
    transition:transform .12s ease-out;
}
.big-arrow {
    width:24px;
    margin-left:24px;
    @include below($m) {
        width:18px;
        margin-left:12px;
    }
}
.course-arrow {
    width:24px;
    transition:transform .12s ease-out;
    transform:translateX(0px);
    @include below($m) {
        width:18px;
        margin-top:12px;
    }
}
.card-symbol {
    position: relative;
    z-index:2;
    @include below($s) {
        max-width:98px;
    }
}