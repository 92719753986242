.mt-g0 {
    margin-top:$gutter / 2;
    @include below($s) {
        margin-top:$gutter / 4;
    }
}
.mt-g1 {
    margin-top:$gutter;
    @include below($s) {
        margin-top:$gutter / 2;
    }
}
.mt-g2 {
    margin-top:$gutter * 2;
    @include below($s) {
        margin-top:$gutter;
    }
}
.mt-g3 {
    margin-top:$gutter * 3;
    @include below($s) {
        margin-top:$gutter * 3 / 2;
    }
}
.mt-g4 {
    margin-top:$gutter * 4;
    @include below($s) {
        margin-top:$gutter * 2;
    }
}
.mob-mt-g1 {
    @include below($s) {
        margin-top:$gutter;
    }
}
.mb-g0 {
    margin-bottom:$gutter / 2;
    @include below($s) {
        margin-bottom:$gutter / 4;
    }
}
.mb-g1 {
    margin-bottom:$gutter;
    @include below($s) {
        margin-bottom:$gutter / 2;
    }
}
.mob-mb-g2 {
    @include below($s) {
        margin-bottom:$gutter * 2;
    }
}
.pb-g0 {
    padding-bottom:$gutter / 2;
    @include below($s) {
        padding-bottom:$gutter / 4;
    }
}
.pb-g1 {
    padding-bottom:$gutter;
    @include below($s) {
        padding-bottom:$gutter / 2;
    }
}
.pb-g2 {
    padding-bottom:$gutter * 2;
    @include below($s) {
        padding-bottom:$gutter;
    }
}
.pt-g1 {
    padding-top:$gutter;
    @include below($s) {
        padding-top:$gutter / 2;
    }
}

.mobile-flex-reverse {
    @include below($l) {
        display: flex !important;
        flex-wrap:wrap;
        flex-direction:column-reverse;
    }
}

.f-right {
    float:right;
}
.d-block {
    display: block;
}

.no-float {
    float: none !important;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.desktop {
    display: block;
    @include below($m) {
        display: none;
    }
}
.mobile {
    display: block;
    @include above($m) {
        display: none;
    }
}