@import 'normalize';
@import 'breaker';
@import 'mixins';

// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 680px;
$m: 990px;
$l: 1050px;
$xl: 1410px;

// Default content settings.
$contentWidth: 1365px;
$contentPadding: 15px;
$contentMaxWidth: 90%;
$siteMaxWidth: 1365px;
$defaultMargin: 24px;
$gutter: 24px;

// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
//Font-face
@font-face {
	font-family: 'edmondsans';
	src: url('../fonts/edmondsans-regular.woff') format('woff');
	font-weight:  300;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'edmondsans';
	src: url('../fonts/edmondsans-medium.woff') format('woff');
	font-weight:  500;
	font-style:   normal;
	font-stretch: normal;
}
@font-face {
	font-family: 'edmondsans';
	src: url('../fonts/edmondsans-bold.woff') format('woff');
	font-weight:  600;
	font-style:   normal;
	font-stretch: normal;
}
//Libs and shit
@import 'grid';

// Colors
$colors: (
    red: #E8361B,
    coal: #484848,
    darkgray: #62686D,
    verydarkgray: #383838,
    bordergray: #E1E5E3,
    darkbordergray:#C2C5C3,
    mint: #ADF3E1,
    darkpurple: #766C77,
    white: #ffffff,
    black: #000000
);
@each $colorValue, $color in $colors {
    .color-#{$colorValue} {
        color:$color !important;
    }
    .bg-#{$colorValue} {
        background-color:$color;
    }
    .border-#{$colorValue}-top {
        border-top:2px solid $color;
    }
    .border-#{$colorValue}-bottom {
        border-bottom:2px solid $color;
    }
}

//Other cosmetics
$defaultShadow:0 0 20px 5px rgba(0,0,0,0.10);
.red-hover {
    transition:color .12s ease-in-out;
    &:hover {
        color:map-get($colors, red);
    }
}
// ::selection {
//     color: map-get($colors, mint);
//     background:map-get($colors, darkgray);
// }
//Typograhpy
body {
    color:map-get($colors, coal);
    @include below($m) {
        padding-top:74px;
        overflow-x:hidden;

    }

	@include below(600px) {
		#wpadminbar {
			position: fixed;
		}
	}
}
p, .serif, ol, ul {
    font-family:'athelas', serif;
    font-size:18px;
    line-height:26px;
    color:map-get($colors, coal);
    @include below($s) {
        font-size:14px;
        line-height:22px;
    }
}

h1, h2, h3, .sans-serif, .link, a {
    font-family:'edmondsans', sans-serif;
}
.link {
    font-weight:500;
    font-size:16px;
    text-decoration: underline;
    @include below($s) {
        font-size:14px;
    }
    &:hover .small-arrow {
        transform:translateX(2px);
    }
    &.big-link {
        font-size:32px;
        display: inline-block;
        float:right;
        @include below(1300px) {
            font-size:24px;
        }
        @include below($m) {
            font-size:18px;
        }
        .big-arrow {
            transform:translateX(0px);
            transition:.12s transform ease-out;
        }
        &:hover .big-arrow {
            transform:translateX(4px);
        }
    }
}
.label-text {
    font-size:16px;
    font-weight:600;
    font-family:'Edmondsans', sans-serif;
}
.label {
    font-size:12px;
    font-weight:600;
    text-transform: uppercase;
    padding:5px 12px 3px 12px;
    display:inline-block;
}
.date {
    margin-left:12px;
    font-weight:600;
    text-transform:capitalize;
    display: inline-block;
    font-family:'edmondsans', sans-serif;
}
///Headings
.heading-small {
    font-size:24px;
    line-height:29px;
    font-weight:600;
}
.heading {
    font-size:32px;
    line-height:36px;
    font-weight:600;
    @include below($m) {
        font-size:24px;
        line-height:32px;
    }
}
.heading-large {
    font-size:64px;
    line-height:68px;
    font-weight:600;
    @include below($m) {
        font-size:30px;
        line-height:36px;
    }
}

.section-preamble {
	a {
		color:map-get($colors, coal) !important;
        text-decoration: none !important;
        &.color-red {
            color:map-get($colors, red) !important;
        }
	}
}
//Base site stuff
section {
    display: block !important;
}
#SiteContent {
    @include below(1300px) {
        padding:0 $gutter;
    }
    @include below($s) {
        padding:0 $gutter / 2;
    }
}

//Themes
.theme-gallery {
    background-color:map-get($colors, darkgrey);
    transition:background-color .18s ease-in-out;
    img {
        mix-blend-mode:multiply;
    }
}
a:hover .theme-gallery {
    background-color:lighten(map-get($colors, darkgrey), 10%);
}
.theme-edu {
    background-color:map-get($colors, red);
    img {
        mix-blend-mode:multiply;
    }
}
.four-oh-holder {
    text-align:center;
    padding:$gutter * 4 $gutter;
    .button {
        margin-right:$gutter;
        &:last-child {
            margin-right:0;
        }
    }
    form {
        margin-top:$gutter *2;
        input {
            border:0;
            appearance: none;
            background:white;
            width:520px;
            max-width:100%;
            padding-left:48px;
            line-height:48px;
            font-size:24px;
            font-weight:500;
            color:map-get($colors, red);
            border-bottom:2px solid map-get($colors, red);
            &:focus {
                outline:none;
            }

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #F9CDC6;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: #F9CDC6;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: #F9CDC6;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: #F9CDC6;
            }
            background-image:url('../img/search.png');
            background-size:24px 24px;
            background-position: left center;
            background-repeat: no-repeat;
        }
    }
}
.four-oh-four {
    h2 {
        font-size:250px;
        font-weight:600;
        @include below($m) {
            font-size:78px;
        }
    }
}

.page-title {
    margin: 40px auto;
    width: 100%;
    max-width: 672px;

    &.has-sidebar {
        max-width: 1020px;

        @media screen and (max-width: 1024px) {
            max-width: 672px;
        }
    }
}


.page-restaurant-hotel {
    width: 100%;
    max-width: 1020px;
    margin: 0 auto;
    display: flex;

    &__content {
        width: 100%;
    }

    &.has-sidebar {
        justify-content: space-between;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
            justify-content: start;
        }

        .page-restaurant-hotel__content {
            max-width: 672px;

            @media screen and (max-width: 1024px) {
                max-width: 1020px;
            }
        }
    }

    img {
        max-width: 100%;
    }

    p {
        font-size: 18px;
        line-height: 26px;
    }

    .layout {
        margin: 50px auto;
        width: 100%;
        max-width: 672px;

        @media screen and (max-width: 640px) {
            margin: 40px auto;
        }

        &:first-of-type {
            margin-top: 0;
        }
    }

    .fact-box-holder {
        padding-left: 25px;
        width: 100%;
        max-width: 346px;

        @media screen and (max-width: 1024px) {
            padding-left: 0;
            max-width: 672px;
            margin: 40px auto;
        }
    }
}

.slider-video {
    width: 100%;
    height: 100%;
    position: relative;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__play-btn {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        z-index: 2;
        cursor: pointer;
        border: 2px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #000;
        transition: background-color .2s ease-in-out, border-color .2s ease-in-out;

        img {
            margin-left: 3px;
        }

        &:hover {
            background-color: #000;
            border-color: #000;
        }
    }
}

.slider-text {
    text-align: center;
    font-size: 24px;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 80px;
    @include below($s) {
        font-size: 18px;
        padding: 0 40px;
    }
}
.slider-text-quote {
    font-size: 120px;
    line-height: 1;
    margin-bottom: -24px;
    color:map-get($colors, red);
    font-family: 'Athelas', sans-serif;
}
.page-restaurant-hotel .slider-text p {
    font-size: 24px;
    margin-bottom: 16px;
    @include below($s) {
        font-size: 18px;
    }
}

.button-link-block {
    display: flex;
    justify-content: center;

    .button {
        margin-top: 0;
    }
}

.cookie-bar {

    position: fixed;
    bottom: 0;
    z-index: 2;
    width: 100%;
    box-shadow:$defaultShadow;
    background: #fff;
    padding: 10px 0;
    .cookie-read-more {
        color: #E8361B;
        margin-top: 10px;
        cursor: pointer;
        font-family: 'athelas', serif;
    }
    .button {
        margin-top: 20px;
        background-color: #ADF3E1;
    }
    &.hidden {
        display: none;
    }

}

//Modules
@import 'modules/buttons';
@import 'modules/navigation';
@import 'modules/page-hero';
@import 'modules/symbols';
@import 'modules/card';
@import 'modules/content-list';
@import 'modules/fact-box';
@import 'modules/article-gallery';
@import 'modules/form';
@import 'modules/lunch';
@import 'modules/header-text-below';
@import 'modules/image-block';
@import 'modules/weekly-lunch';
@import 'modules/room-card';
@import 'modules/facts-list';
@import 'modules/sub-page-menu';
@import 'modules/contact-block';
@import 'modules/map-contact-block';
@import 'modules/image-slider';

//Templates
@import 'templates/courses';
@import 'templates/page-article';
@import 'templates/wysiwyg';
@import 'templates/contact';
@import 'templates/project';
@import 'helpers';

//Libs
@import 'swiper';
@import 'photoswipe';