.layout-conference_room_block,
.layout-hotel_room_block {
    max-width: 903px !important;
}

.room-card {
    display: flex;
    flex-direction: column;
    max-width: 892px;
    margin: 0 0 30px 0;

    &__wrapper {
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 640px) {
            flex-direction: column;
        }
    }

    &__slider {
        width: 50%;
        overflow: hidden;
        position: relative;
		min-height: 250px;

        @media screen and (max-width: 640px) {
            width: 100%;
        }

        .swiper-slide {
            width: 100% !important;
            height: auto;
            padding-bottom: 0;

            .gallery-image {
                min-width: 100%;
                height: 100%;

                img {
                    object-fit: cover;
                    width: 100%;
                    position: absolute;
                    height: 100%;

                    @media screen and (max-width: 640px) {
                        max-width: 100%;
                        height: auto;
                        position: relative;
                        object-fit: unset;
                    }
                }

                &:after {
                    content: 'Klicka för större bild';
                    background-image: none;
                    background-color: rgba(#000, 0);
                    color: #fff;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 600;
                    text-transform: none;
                }

                &:hover {
                    &:after {
                        background-color: rgba(#000, 0.5);
                    }
                }
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            width: 62px;
            height: 62px;
            background-color: #383838;
            background-image: url('../img/arrow-white.png');
            background-size: 24px 18px;
            transition: all .2s ease-in-out;

            &:hover {
                background-color: #000
            }
        }

        .swiper-button-next {
            right: 0;
        }

        .swiper-button-prev {
            left: 0;
            transform: rotate(-180deg);
        }
    }



    &__content {
        width: 50%;
        padding: 35px 32px;
        background-color: #E1E5E3;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 640px) {
            width: 100%;
        }

        @media screen and (max-width: 420px) {
            padding: 20px;
        }

        h3, p, span {
            font-size: 18px;
            line-height: 26px;
        }

        h3 {
            font-weight: bold;
            margin-bottom: 8px;
        }

        span {
            font-weight: bold;
            margin-top: 18px;
            display: block;
        }
    }

    &__content-footer {
        display: flex;
        margin-top: 30px;
    }

    &__button {
        padding: 7px;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        color: #fff;
        text-align: center;
        width: calc(50% - 2px);
        outline: none;
        border: none;
        font-family: 'edmondsans', sans-serif;
        transition: opacity .2s ease-in-out;

        &:hover {
            opacity: 0.7;
        }

        &:first-of-type {
            margin-right: 2px;
        }
    }

    &__desc-wrapper {
        padding: 70px;
        border: solid 2px #DADADA;
        display: none;

        @media screen and (max-width: 640px) {
            padding: 20px;
        }

        &.open {
            display: block;
        }
    }

    &__desc-list-wrapper {
        display: flex;
        margin: 57px 0 0 0;
        padding: 64px 0 0 0;
        border-top: 2px solid #62686D;

        @media screen and (max-width: 640px) {
            margin: 35px 0 0 0;
            padding: 35px 0 0 0;
            flex-direction: column;
        }
    }

    &__desc-list {
        width: 50%;

        &:last-child {
            padding-left: 11%;

            @media screen and (max-width: 640px) {
                padding-left: 0;
                padding-top: 35px;
            }
        }

        @media screen and (max-width: 640px) {
            width: 100%;
        }

        &--border {
            border-right: 2px solid #62686D;

            @media screen and (max-width: 640px) {
                border-right: none;
                border-bottom: 2px solid #62686D;
                padding: 0 0 35px 0;
            }
        }
    }

    &__desc-list-item {
        display: flex;
        margin: 0 0 5px 0;
        font-family: 'athelas', serif;

        img {
            width:auto;
            height: auto;
			max-height:26px;
            margin-right: 27px;
        }
    }
}
