.header-text-below {
    width: 100%;
    position: relative;
    margin: 25px 0 73px 0;

    &__img-holder {
        display: block;

        img {
            max-width: 100%;
            display: block;
        }
    }

    &__content {
        width: 100%;
        margin: 28px auto 0 auto;
        text-align: center;

        .desktop svg {
            margin: 15px 0;
        }

        p {
            font-size: 18px;
            line-height: 26px;
        }
    }

    &__title {
        max-width: 672px;
        margin: 10px auto 30px auto;
    }

    &__text {
        margin: 0 auto;
        max-width: 620px;
    }
}