.page-hero {
    min-height:400px;
    margin-top:24px;
    padding:64px 48px;
    position: relative;
    overflow:hidden;
    background-size:cover;
    display: flex;
    align-items:center;
    &.subpage-hero {
        background-blend-mode: multiply;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            &:before {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                opacity:0.8;
                left:0;
                top:0;
                z-index:0;
                background: map-get($colors, darkgrey);
            }
        }
        @supports (-ms-ime-align:auto) {
            &:before {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                opacity:0.8;
                left:0;
                top:0;
                z-index:0;
                background: map-get($colors, darkgrey);
            }
        }
    }
    @include below($s) {
        padding:24px;
        min-height:0;
    }
    &.start-hero-overlay {
        background-blend-mode: multiply;
    }
}
.page-hero-background {
    background-size:cover;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:0;
}
.overlay {
    opacity:0.5;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:0;
}
.subpage-hero .page-hero-content {
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    text-align:center;
    max-width:100%;
    @include below($m) {
        position: static;
        transform: translate(0%, 0%);
        padding:$gutter * 2 0;
    }
}
.page-hero-content {
    max-width:70%;
    position: relative;
    z-index:1;
    @include below($m) {
        max-width:100%;
    }
}
.page-hero-content-image {
    width:100%;
    text-align:center;
    img {
        display: inline-block;
        width:30%;
    }
}
.header-pattern {
    position: absolute;
    top:0;
    left:0;
    height:100%;
    opacity:0.1;
    @include below($m) {
        height:100%;
    }
}
.subpage-sidebar, .subpage-content-wrap {
    @include below($m) {
        float:none !important;
    }
}