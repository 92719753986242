.map-contact {
    width: 100%;
    max-width: 1368px;
    margin: 78px auto 48px auto;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 928px) {
        flex-direction: column;
        margin: 40px auto;
    }

    &__info {
        width: 100%;
        max-width: 440px;
        padding: 40px 25px;
        margin: 0 auto;
        background: #E1E5E3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 928px) {
            max-width: none;
            align-items: flex-start;
        }

        &-holder {
            width: 100%;
            max-width: 207px;

            @media screen and (max-width: 928px) {
                max-width: none;
            }

            &:first-of-type {
                border-bottom: 2px solid #C4C4C4;
                padding: 0 0 40px 0;
                margin: 0 0 40px 0;
            }

            h4 {
                font-weight: 600;
                margin: 0 0 8px 0;
            }

            h4, p {
                font-size: 18px;
                line-height: 26px;
                font-family: "Edmondsans";
            }

            a {
                font-size: 18px;
                line-height: 26px;
                font-family: "Edmondsans";
                color: #EF4D22;
                text-decoration: none;
                font-weight: 600;
                margin: 40px 0 0 0;
                display: inline-block;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__map {
        width: 100%;
        max-width: 928px;

        @media screen and (max-width: 928px) {
            max-width: none;
        }

        img {
            max-width: 100%;
            display: block;
        }
    }
}