.fact-box {
    background:map-get($colors, bordergray);
    padding:24px;
    &.course-facts {
        padding:48px 24px;
    }
    &.sticky {
        position: sticky;
        top:24px;
    }
    .button {
        margin-top:$gutter;
        padding-left:12px;
        padding-right:12px;
        width:100%;
    }
    @include below($s) {
        padding:12px;
        margin-bottom:24px;
        &.course-facts {
            padding:24px 12px;
        }
    }
}

.fact-box-header {
    padding-bottom:12px;
    border-bottom:1px solid map-get($colors, darkbordergray);
}

.fact-box-item {
    display: block;
    position: relative;
    p {
        font-weight:500;
        display: inline;
        font-family:'Edmondsans', sans-serif;
    }
    img {
        width:18px;
        position: relative;
        top:3px;
        margin-left:12px;
        cursor:help;
    }
    a {
        color:map-get($colors, red);
        text-decoration: underline;

    }
    ul {
        padding-left:18px;
        margin:6px 0;
    }
    li {
        font-weight:500;
        font-family:'Edmondsans', sans-serif;
        font-size:18px;
    }
}
.fact-extra-holder {
    display: inline-block;
    &:hover .fact-extra {
        opacity:1;
        pointer-events:initial;
    }
}
.fact-extra {
    opacity:0;
    pointer-events:none;
    position: absolute;
    width:220px;
    padding:12px;
    left:-110px;
    z-index:99;
    background:map-get($colors, red);
    box-shadow:$defaultShadow;
    border-radius:3px;
    p {
        color:white;
        font-size:12px;
        line-height:18px;
        font-style:italic;
        font-family:'Edmondsans', sans-serif;
        font-weight:500;
    }
    ul, ol, h1, h2, h3, h4, h5 {
        color: white;
    }
    @include below($s) {
        left:0;
    }
}
