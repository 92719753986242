.sub-page-menu {
    width: 100%;
    max-width: 672px;
    margin: 73px auto 88px auto;
    border: 1px solid #EF4D22;

    @media screen and (max-width: 640px) {
        margin: 40px auto 60px;
    }

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border-bottom: 1px solid #EF4D22;
        padding: 18px 24px;
        cursor: pointer;
        display: none;

        @media screen and (max-width: 640px) {
            display: flex;
        }

        h4 {
            color: #EF4D22;
            font-size: 18px;
            font-weight: 600;
        }
    }

    &__button {
        width: 18px;
        height: 12px;
        position: relative;
        display: flex;
        align-items: center;
        color: #EF4D22;
        font-size: 20px;
        font-weight: 600;
        text-align: center;

        span {
            width: 100%;
            height: 2px;
            display: block;
            background-color: #EF4D22;

            &:after,
            &:before {
                content: "";
                width: 100%;
                height: 2px;
                display: block;
                background-color: #EF4D22;
                position: absolute;
            }

            &:after {
                bottom: 0;
            }

            &:before {
                top: 0;
            }
        }
    }

    &__wrapper {
        width: 100%;
        max-width: 660px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px;

        @media screen and (max-width: 640px) {
            flex-direction: column;
            padding: 0;
            max-width: none;
            display: none;
        }

        @media screen and (max-width: 640px) {
            &.open {
				border-top: 1px solid #EF4D22;
                display: block;
            }
        }
    }

    .page_item {
        margin: 5px 12px;
        text-align: center;
        list-style: none;
        position: relative;
        transition: color .2s ease-in-out;

        @media screen and (max-width: 640px) {
            margin: 0;
            text-align: left;
            border-bottom: 1px solid #EF4D22;

            &:last-of-type {
                border-bottom: 0;
            }

            & > a {
                display: block;
                padding: 18px 24px;
            }
        }

        &:hover > a {
            color: #EF4D22;
        }

        a {
            text-decoration: none;
            color: #484848;
            font-weight: 600;
            width: 100%;

            @media screen and (max-width: 640px) {
                font-size: 18px;
            }
        }
    }

    .current_page_item > a,
    .current_page_ancestor > a {
        color: #EF4D22;
        // border-bottom: 1px solid #EF4D22;
    }

    .page_item_has_children {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;

        & > a {
            width: auto;
        }

        .sub-button {
            display: none;
        }

        @media screen and (max-width: 640px) {
            align-items: flex-start;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .sub-button {
                padding: 15px 27px;
                font-size: 22px;
                cursor: pointer;
                display: block;
            }
        }

        &:after {
            content: "";
            width: 9px;
            height: 6px;
            display: block;
            margin-left: 5px;
            background-size: cover;
            background-image: url('../img/small-arrow-down.svg');

            @media screen and (max-width: 640px) {
               display: none;
            }
        }

        &:hover {
            @media screen and (min-width: 640px) {
                .children {
                    animation: fadeInUp .2s ease-in-out forwards;
                }
            }
        }

        .children {
            left: 0;
            right: 0;
            top: 20px;
            margin: 0 auto;
            position: absolute;
            z-index: 2;
            padding: 13px 14px 23px 14px;
            background-color: #F2F2F2;
            border: 1px solid #EF4D22;
            opacity: 0;
			min-width: 120px;

            @media screen and (max-width: 640px) {
                opacity: 1;
                display: none;
                position: relative;
                top: 0;
                margin: 0;
                width: 100%;
                padding: 15px 24px;
                border: none;
                border-top: 1px solid #EF4D22;

                &.open {
                    display: block;
                }
            }

            .page_item {
                width: 100%;
                text-align: center;
                padding: 10px 0;
                margin: 0;
                border-bottom: 2px solid #E1E5E3;

                @media screen and (max-width: 640px) {
                    padding: 6px 0;

                    &:first-of-type {
                        padding-top: 0;
                    }
                }

                a {
                    font-size: 14px;

                    @media screen and (max-width: 640px) {
                        padding: 7px 0;
                    }
                }
            }
        }
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        pointer-events: none;
    }

    100% {
        opacity: 1;
        pointer-events: auto;
    }
}