.card {
    padding:24px;
    min-height:348px;
    box-shadow:$defaultShadow;
    margin-bottom:$gutter;
    transition:background-color .12s ease-out;
    background-color:map-get($colors ,darkgrey);
    will-change:background-color, transform;
    backface-visibility: hidden;
    background-blend-mode: multiply;
    background-size:cover;
    background-repeat: no-repeat;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
        &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            opacity:0.8;
            left:0;
            top:0;
            z-index:0;
            background: map-get($colors, darkgrey);
        }
    }
    @supports (-ms-ime-align:auto) {
        &:before {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                opacity:0.8;
                left:0;
                top:0;
                z-index:0;
                background: map-get($colors, darkgrey);
            }
    }
    &.edu {
        background-color:map-get($colors ,red);
        &:before {
            background-color:map-get($colors, red);
        }
        p, h2 {
            color:white;
        }
        &:hover {
            background-color:darken(map-get($colors, red), 5%);
            &:before {
                background-color:darken(map-get($colors, red), 5%);
            }
        }
    }
    &.project {
        background-color:map-get($colors ,darkpurple);
        &:before {
            background-color:map-get($colors,darkpurple);
        }
        p, h2 {
            color:white;
        }
        &:hover {
            background-color:darken(map-get($colors, darkpurple), 5%);
            &:before {
                background-color:darken(map-get($colors, darkpurple), 5%);
            }
        }
    }
    &.no-business {
        p, h2 {
            color:white;
        }        
    }
    &.commercial {
        background-color:map-get($colors ,mint);
        background-blend-mode:normal;
        &:before {
            opacity:0;
        }
        position: relative;
        p, h2 {
            color:map-get($colors, coal);
        }
        &:hover  {
            background-color:darken(map-get($colors, mint), 5%);
            .commercial-overlay {
                background-color:darken(map-get($colors, mint), 5%);
            }
        }
        .commercial-overlay {
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-color:map-get($colors, mint);
            opacity:0.8;
            transition:opacity .18s;
        }
    }
    &.restaurant {
        background-color:map-get($colors ,darkgrey);
        &:before {
            background-color:map-get($colors,darkgrey);
        }
        p, h2 {
            color:map-get($colors, mint);
        }
        &:hover {
            background-color:darken(map-get($colors, darkgrey), 5%);
            &:before {
                background-color:darken(map-get($colors, darkgrey), 5%);
            }
        }
    }
    .arrow-in-card {
        will-change:transform;
        transform:translateX(0px);
        transition:transform .12s ease-out;
        backface-visibility: hidden;
    }
    &:hover {
        background-color:darken(map-get($colors ,darkgrey), 5%);
        &:before {
            background-color:darken(map-get($colors, darkgrey), 5%);
        }
        .arrow-in-card {
            transform:translateX(4px);
        }
    }
    @include below($m) {
        box-shadow:none;
    }
    @include below($s) {
        min-height:251px;
    }
}
.card-content {
    position: absolute;
    left:24px;
    right:24px;
    bottom:24px;
}
.image-in-card {
    position: absolute;
    width:85%;
    height:auto;
    left:24px;
    top:50%;
    transform:translateY(-50%);
}
//Article card
.article-card {
    box-shadow:$defaultShadow;
    background:map-get($colors, bordergray);
    a {
        text-decoration: none;
        &:hover {
            h2 {
                text-decoration: underline;
            }
        }
    }
    &.contact-card {
        margin-bottom:$gutter;
        a {
            text-decoration: underline;
            word-break:break-all;
        }
    }
    .contact-column {
        display: inline-block;
        margin-right:$gutter * 2;
    }
    @include below($m) {
        box-shadow:none;
    }
}
.article-card-image {
    width:100%;
    display: block;
    position: relative;
    img {
        width:100%;
        height:auto;
        display: block;
        &.small-pattern, &.small-gallery {
            mix-blend-mode:normal;
        }
        &.small-gallery {
            position: absolute;
            left:12px;
            top:12px;
            width:48px;
        }
    }
}
.article-card-content {
    padding:12px 24px;
    background:map-get($colors, bordergray);
    &.contact-card-content {
        padding:24px;
        p {
            font-size:16px;
            font-weight:500;
        }
    }
}
.contact-in-article .contact-card {
    max-width:325px;
}
.contact-in-article {
    margin-bottom:$gutter * 2;
}