.main-menu-wrap {
    padding:40px 0 30px 0;
    border-bottom:2px solid map-get($colors, red);
    @include below(1300px) {
        padding:40px $gutter 30px $gutter;
    }
}
.sticky-menu {
    position: relative;
    top:0px;
    z-index:9;
    background:white;
}
.main-nav-links {
    display: inline-block;
    position: relative;
    top: -4px;
    z-index:3;
}
.main-nav-link {
    color:map-get($colors, coal);
    font-size:18px;
    text-decoration: none;
    transition:.12s all ease-in-out;
    margin-right:60px;
    @extend .red-hover;
    @include below($l) {
        margin-right:30px;
    }
    &.active {
        color:map-get($colors, red);
        text-decoration: underline;
    }
}
.main-nav-actions {
    position: absolute;
    right:0;
    bottom:0;
}
.main-nav-action {
    display: inline-block;
    margin-left:36px;
    position: relative;
    z-index:3;
    &.search-action {
        z-index:1;
    }
}
.logotype {
    float:left;
    @include below($l) {
        img {
            width:180px;
        }
    }
}

.main-nav {
    float:right;
    padding-top:40px;
    position: relative;
    padding-right:100px;
    @include below($l) {
        padding-top:30px;
    }
}

.extras-nav {
    position: absolute;
    top:9px;
    right:0;
    z-index:1;
    a {
        font-size:12px;
        font-weight:600;
        text-decoration: none;
        padding:10px 12px 9px 12px;
        display: inline-block;
        position: relative;
        margin-right:12px;
        &:last-child {
            margin-right:0;
        }
        &:hover {
            text-decoration: underline;
        }
        img {
            margin-left:12px;
            position: absolute;
            display: inline;
            right:12px;
            top:50%;
            transform:translateY(-50%);
        }
        &.with-icon {
            padding-right:44px;
        }
    }
}

.translate-wrap {
    position: absolute;
    right: 0;
    z-index: 9999999;
    top: 0;
    display: none;
    &.visible {
        display: block;
    }
}
.main-nav-submenu-wrap {
    box-shadow:$defaultShadow;
    pointer-events:none;
    opacity:0;
    padding:78px 48px 48px 48px;
    background:white;
    width:calc(100% + 32px);
    transform:translate(-5%, -60px);
    z-index:2;
    transition:opacity .18s ease-in-out;
    position: absolute;
    &.visible {
        pointer-events:initial;
        opacity:1;
    }
}

.main-nav-submenu-items {
    border-top:1px solid map-get($colors, bordergray);
    padding-top:24px;
}
.main-nav-submenu-item {
    margin-top:12px;
    display: inline-block;
    margin-right:60px;
    vertical-align: top;
    &:last-child {
        margin-right:0;
    }
    a {
        font-weight:500;
        font-size:16px;
        display: block;
        margin-bottom:12px;
        color:map-get($colors, coal);
        text-decoration: underline;
        @extend .red-hover;
        &:last-child {
            margin-bottom:0;
        }
        &.active {
            color:map-get($colors, red);
        }
    }
}

//Search things
.search-boi {
    position: absolute;
    width:100%;
    top:16px;
    left:0;
    z-index:99;
    opacity:0;
    transition:opacity .12s;
    pointer-events:none;
    &.visible {
        opacity:1;
        pointer-events:initial;
    }
    input {
        border:0;
        appearance: none;
        background:white;
        width:94.6%;
        padding-left:48px;
        line-height:48px;
        font-size:24px;
        font-weight:500;
        color:map-get($colors, red);
        border-bottom:2px solid map-get($colors, red);
        &:focus {
            outline:none;
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #F9CDC6;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #F9CDC6;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: #F9CDC6;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: #F9CDC6;
        }
        background-image:url('../img/search.png');
        background-size:24px 24px;
        background-position: left center;
        background-repeat: no-repeat;
    }
    .close-search {
        position: absolute;
        right:0;
        top:50%;
        transform:translateY(-50%);
        width:24px;
        cursor:pointer;
    }
}

//Footer
.footnote {
    text-align:center;
    padding:$gutter * 2 $gutter;
    font-family:'edmondsans', sans-serif;
    font-size:18px;
    background-color:map-get($colors, bordergray);
}
.footer-content {
    background:map-get($colors, bordergray);
    padding:48px 0;
    @include below(1300px) {
        padding:48px 24px;
    }
    @include below($m) {
        text-align: center;
    }

}
.footer-logos {
    display: inline-block;
}
.footer-info {
    float:right;
     @include below($m) {
         float:none;
     }
}
.info-column {
    display: inline-block;
    margin-right:48px;
    vertical-align: top;
    p, a {
        font-weight:500;
        font-size:18px;
        line-height:26px;
        display: block;
        margin-bottom:10px;
    }
    @include below($m) {
        display: block;
        margin-right:0;
        .so-me {
            display: inline-block;
            margin:0 6px;
            vertical-align: top;
            img {
                height:24px;
            }
        }
    }
}
.footer-bottom {
    margin-top:24px;
    padding-top:24px;
    border-top:2px solid map-get($colors, red);
    p {
        font-weight:600;

    }
}
.headmen {
    display: inline-block;
    vertical-align: top;
    a {
        text-decoration: none;
    }
    img {
        height:47px;
        width:auto;
        margin-right:24px;
    }
     @include below($m) {
         display: block;
         margin-bottom:$gutter;
         a:last-child img {
             margin-right:0px;
         }
     }
}
.slogan {
    background:map-get($colors, red);
    padding:96px 0;
    text-align:center;
    h2 {
        font-family:'Athelas', sans-serif;
        font-size:64px;
        font-weight:600;
        color:white;
    }
    @include below($l) {
        padding:67px 0;
        h2 {
            font-size:32px;
            line-height:36px;
        }
    }
}

.important-links {
    background-color:map-get($colors, bordergray);
    padding:14px 24px 16px 24px;
    display: inline-block;
    span {
        font-weight:600;
        display: inline-block;
        margin-right:12px;
    }
    .link {
        margin-right:24px;
        &:last-child {
            margin-right:0;
        }
        @include below($s) {
            display: block;
            margin:6px 0;
        }
    }
    @include below($s) {
        display: block;
        padding:12px;
    }
}

//Mobile nav
.mobile-nav {
    position: fixed;
    top: 0;
    width: 100%;
    padding:0 12px;
    display: block;
    background:#fff;
    z-index:99;
    height:74px;
    hr {
        position: absolute;
        bottom:0;
        height:2px;
        background:map-get($colors, red);
        border:0;
        left:12px;
        right:12px;
        margin:0;
    }
    @include above($s) {
        padding:0 24px;
        hr {
            left:24px;
            right:24px;
        }
    }

	.admin-bar & {
		top: 46px;
	}
}
.mobile-logotype {
    position: absolute;
    left:12px;
    top:50%;
    transform:translateY(-50%);
    img {
        width:120px;
    }
    @include above($s) {
        left:24px;
        img {
            width:180px;
        }
    }
}
.mobile-nav-actions {
    position: absolute;
    right:12px;
    top:50%;
    transform:translateY(-50%);
    img {
        height:24px;
    }
    @include above($s) {
        right:24px;
    }
}
.mobile-nav-action {
    display: inline-block;
    position: relative;
    z-index:3;
    &.search-action {
        z-index:1;
    }
}
.mobile-menu {
    position: fixed;
    top:74px;
    left:0;
    width: 100%;
    height:89vh;
    border:12px solid white;
    border-top:0;
    background:map-get($colors, red);
    padding:24px;
    opacity:0;
    pointer-events:none;
    transition:opacity .18s;

    .scrollable-mobile-menu {
        overflow-y: scroll;
        height: 100%;
        padding-bottom: 100px;
    }
    &.visible {
        opacity:1;
        pointer-events:initial;
    }
    @include above($s) {
        border-width:24px;
    }

	.admin-bar & {
		top: 120px;
	}
}
.mobile-main-links {
    padding-bottom:24px;
    border-bottom:2px solid white;
    a {
        font-size:18px;
        display: block;
        color:white;
        font-weight:600;
        margin-bottom:12px;
        &:last-child {
            margin-bottom:0;
        }
    }
}
.mobile-sub-links {
    margin-top:24px;
    a {
        font-size:16px;
        color:white;
        display: block;
        margin-bottom:6px;
        font-weight:500;
    }
}
.mobile-sub-column {
    display: inline-block;
    margin-right:48px;
    vertical-align: top;
    &:last-child {
        margin-right:0;
    }
}
.mobile-extras {
    position: absolute;
    bottom:0;
    background:map-get($colors, bordergray);
    width:100%;
    left:0;
    padding:6px 0;
    a {
        display: inline-block;
        font-size:14px;
        text-decoration: none;
        font-weight:600;
        width:48%;
        border-right:1px solid map-get($colors, red);
        text-align:center;
        padding:9px 0 11px 0;
        &:last-child {
            border:0;
        }
    }
}
.mobile-search {
    margin-top:48px;
    width:90%;
    background:transparent;
    border:0;
    border-bottom:2px solid white;
    padding:0 0 3px 24px;
    background-image:url('../img/search-white.png');
    background-repeat: no-repeat;
    background-position: left 0px;
    background-size:16px 16px;
    font-weight:500;
    color:white;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #FFF;
        font-style: italic;
        font-weight:500;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: #FFF;
        font-style: italic;
        font-weight:500;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: #FFF;
        font-style: italic;
        font-weight:500;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: #FFF;
        font-style: italic;
        font-weight:500;
    }

}

#__ba_panel {
    display: none !important;
}