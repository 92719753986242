.courses-info {
    display: block;
    p {  
        font-size: 24px;
        line-height: 32px;
        font-weight:600;
        max-width:90%;
        @include below($m) {
            font-size:18px;
            line-height:24px;
            max-width:100%;
        }
    }
}

.course-search {
    border:0;
    appearance: none;
    border-bottom:2px solid map-get($colors, red);
    width:100%;
    padding-left:48px;
    line-height:48px;
    font-size:24px;
    font-weight:500;
    color:map-get($colors, red);
    &:focus {
        outline:none;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #F9CDC6;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: #F9CDC6;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: #F9CDC6;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: #F9CDC6;
    }
    background-image:url('../img/search.png');
    background-size:24px 24px;
    background-position: left center;
    background-repeat: no-repeat;
    @include below($s) {
        font-size:16px;
        background-size:16px 16px;
        padding-left:24px;
        line-height:24px;
    }
}

.course-list-row, .course-list-head {
    display: block;
    padding-left:12px;
    padding-right:12px;
    @include below($m) {
        padding-left:0;
        padding-right:0;
    }
}

.course-list-row {
    padding:24px 12px;
    border-top:1px solid map-get($colors, bordergray);
    &:hover {
        h3 {
            text-decoration: underline;
        }
        .course-arrow {
            transform:translateX(3px);
        }
    }
    h3, h4 {
        font-size:18px;
        color:map-get($colors, coal);
        font-family:'Edmondsans', sans-serif;
        font-weight:500;
    }
    h3 {
        font-weight:600;
    }
    &.hidden {
        display: none;
    }
    &.visible {
        display: block !important;
    }
    &:last-child {
        border-bottom:1px solid map-get($colors, bordergray);
    }
    @include below($m) {
        padding:12px 0;
    }
}
.course-data-mobile {
    display: block;
    span, h4 {
        font-size:12px;
        @include above($s) {
            font-size:18px;
        }
    }
    span {
        font-weight:600;
    }
    h4 {
        display: inline-block;
    }
}
.course-list-mobile {
    display: block;
    h3 {
        margin-bottom:8px;
    }
}