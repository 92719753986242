.contact-filter {
    width:80%;

    span {
        background:map-get($colors, bordergray);
        color:map-get($colors, coal);
        display: inline-block;
        margin-right:$gutter / 2;
        margin-bottom:$gutter / 2;
        padding:8px 12px 6px 12px;
        font-size:16px;
        font-weight:600;
        font-family:'Edmondsans', sans-serif;
        cursor:pointer;
        transition:.12s all ease-out;
        &:hover {
            background:darken(map-get($colors, bordergray), 5%);
        }
        &.active {
            background:map-get($colors, red);
            color:white;
            &:hover {
                background:map-get($colors, red);
            }
        }
    }
    @include below($m) {
        width:100%;
    }
}