.form {
    background-color:map-get($colors, bordergray);
    display: block;
    width:100%;
    padding:48px 24px;
    margin-top:$gutter * 2;
    margin-bottom:$gutter * 2;
    h3 {
        /* Skicka en bokningsfö: */
        font-family: 'Athelas';
        font-size: 24px;
        color: #484848;
        line-height: 32px;
        font-weight:600;
        margin-bottom:12px;
    }
    form {
        margin-top:24px;
    }
}
.input-wrap {
    margin-bottom:24px;
    label {
        font-size:12px;
        font-weight:600;
        margin-bottom:6px;
        display: block;
        font-family:'Edmondsans', sans-serif;
    }
    input, textarea {
        border:0;
        background-color:#EDEFEE;
        border-bottom:1px solid lighten(#62686D, 40%);
        width:100%;
        padding:0 12px;
        font-size:18px;
        line-height:48px;
        font-family:'Edmondsans', sans-serif;
        font-weight:500;
        transition:background-color .18s ease-out;
        &:focus, &:active {
            outline:none;
            background-color:white;
        }
        &.error {
            border:1px solid red;
        }
    }
}
.email {
    font-family:'Edmondsans', sans-serif;
    font-weight:600;
    font-size:28px;
    margin-bottom:24px;
    color:green;
    &.error {
        color:red;
    }
}