.single-project-article {
    .article-content-width {
        max-width:676px;
        margin:0 auto;
    }
    .article-inner-width {
        max-width:900px;
        margin:0 auto;
    }
    .chapter {
        position: relative;
        margin-top:$gutter * 2;
        .chapter-label {
            position: absolute;
            left:0;
            top:0;
            max-width:227px;
            
            .number {
                font-family:'Athelas', serif;
                font-size:40px;
                font-weight:600;
            }
            .title {
                font-size:32px;
                line-height:42px;
                font-weight:600;
            }
            @include below($m) {
                position: static;
                max-width:100%;
                margin-bottom:$gutter;
                .number {
                    font-size:28px;
                }
                .title {
                    font-size:22px;
                    line-height:32px;
                }
            }
        }
    }
    .large-image {
        margin:$gutter * 2 auto;
        img {
            width:100%;
            display: block;
            height:auto;
        }
        .caption {
            width:100%;
            text-align:center;
            margin-top:$gutter / 2;
            font-size:16px;
        }
    }
    //Wysiwyg stuff
    h4 {
        font-size:24px;
        line-height:32px;
        margin-bottom:6px;
        color:map-get($colors, coal);
        font-family:'Athelas', serif;
        font-weight:600;
    }
    .quote blockquote, blockquote {
        width:100%;
        font-size:64px;
        line-height:71px;
        color:map-get($colors, red);
        margin:$gutter * 2 auto;
        font-family:'Athelas', serif;
        font-weight:600;
        text-align:center;
        @include below($m) {
            font-size:32px;
            line-height:41px;
        }
    }
}