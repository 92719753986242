.contact-block {
    width: 100%;
    margin: 50px 0 0 0;

    &__header {
        width: 100%;
        position: relative;

        &-logo {
            width: 100%;
            max-width: 143px;
            background-color: #fff;
            padding: 0 32px;
            z-index: 1;
            position: relative;
            margin: 0 auto;
            text-align: center;

            img {
                max-width: 100%;
                display: block;
                margin: 0 auto;
            }
        }

        &:after {
            content: "";
            width: 100%;
            height: 2px;
            background-color: #EF4D22;
            display: block;
            position: absolute;
            z-index: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__wrapper {
        width: 100%;
        max-width: 672px;
        margin: 0 auto;
    }

    &__title {
        width: 100%;
        text-align: center;
        margin: 90px auto 40px auto;

        @media screen and (max-width: 640px) {
            margin: 40px auto;
            max-width: 298px;
        }
    }

    &__text {
        margin: 0 auto;
        text-align: center;

        @media screen and (max-width: 640px) {
            max-width: 298px;
        }

        p {
            font-size: 18px;
            line-height: 26px;
        }
    }

    &__persons {
        width: 100%;
        margin: 78px auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        @media screen and (max-width: 640px) {
            margin: 43px auto;
			flex-direction: column;
        }
    }

    &__person {
        width: calc(100% / 2 - 24px);
		max-width: calc(100% / 2 - 24px);
        margin: 12px;
		flex: 1;

        @media screen and (max-width: 640px) {
            width: 100%;
			max-width: 377px;
			margin: 12px auto;
        }

		&-inner {
			height: 100%;
			display: flex;
			flex-direction: column;
			flex: 1;
		}

        &-img img {
            max-width: 100%;
            display: block;
        }

        &-info {
            padding: 24px;
            background-color: #E1E5E3;
			flex: 1;

            h3 {
                font-size: 24px;
                line-height: 26px;
                font-weight: 600;
                color: #383838;
                font-family: "Edmondsans";
            }

            p {
                font-weight: 500;
                color: #383838;
                font-family: "Edmondsans";
                margin: 2px 0 8px 0;
            }

            a {
				font-size: 16px;
				line-height: 20px;
				font-weight: 500;
				color: #EF4D22;
				display: block;
				text-decoration: none;
				word-break: break-all;
				margin-bottom: 10px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}