.image-slider {
    width: 100%;
    overflow: hidden;
    position: relative;

    .swiper-slide {
        width: 100% !important;
        height: auto;
        padding-bottom: 0;
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 32px;
        height: 32px;
        background-color: #383838;
        background-image: url('../img/arrow-white.png');
        background-size: 18px 14px;
        transition: all .2s ease-in-out;

        &:hover {
            background-color: #000
        }
        @include below ($s) {
            width: 20px;
            height: 20px;
            background-size: 14px 10px;
        }
    }

    .swiper-button-next {
        right: 0;
    }

    .swiper-button-prev {
        left: 0;
        transform: rotate(-180deg);
    }
}