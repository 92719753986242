.button, input[type=button] {
    padding:14px 40px 13px 40px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    font-size:16px;
    font-weight:600;
    text-decoration: none;
    text-transform:uppercase;
    line-height:16px;
    color:map-get($colors, coal);
    background-color:white;
    margin-top:$gutter * 2;
    display: inline-block;
    transition:all .12s ease-out;
    transform:translate(0px, 0px);
    min-width:221px;
    text-align:center;
    appearance: none;
    border:0;
    @include below($s) {
        font-size:12px;
        min-width:195px;
    }
    &:hover {
        transform:translate(-2px, -2px);
        box-shadow: 0px 8px 10px 0px rgba(232, 54, 27, 0.2);
    }
    &:active {
        transform:translate(0px, 0px);
        box-shadow: 0px 4px 6px 0px rgba(232, 54, 27, 0.1); 
    }
    &.red {
        background-color:map-get($colors, red);
        color:white;
    }
    &.gray {
        background-color:#f0f0f0;
        color:map-get($colors, coal);
    }
}
input[type=button] {
    margin-top:$gutter;
}