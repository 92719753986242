.layout-image_block {
    margin: 20px 0;
    width: 100%;
    max-width: 672px;

    img {
        max-width: 100%;
        display: block;
    }

    figcaption {
        width: 100%;
        font-size: 18px;
        line-height: 26px;
        background-color: #484848;
        padding: 25px 28px;
        color: #fff;
        font-family: 'athelas', serif;
    }
}