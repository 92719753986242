.facts-list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }

    &__title {
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 20px;

        @media screen and (max-width: 640px) {
            font-size: 20px;
        }
    }

    &__wrapper {
        width: 50%;
        margin-bottom: 40px;

        &:last-of-type {
            margin-bottom: 0;
        }

        @media screen and (max-width: 640px) {
            width: 100%;
        }

        &:nth-child(odd) {
            padding-right: 20px;

            @media screen and (max-width: 640px) {
                padding-right: 0;
            }
        }    
    }
    
    &__list {
        &-item {
            display: flex;
            margin: 0 0 5px 0;
            font-family: 'athelas', serif;
    
            img {
                width: 16px;
                height: 26px;
                margin-right: 27px;
            }
        }
    }
}